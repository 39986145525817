/** @format */

import { useState } from "react";

export default function TodoEntryForm({ adder }) {
  const [name, setContent] = useState("");
  function submit(e) {
    e.preventDefault();
    adder(name);
    setContent("");
  }
  return (
    <form onSubmit={submit}>
      <input
        placeholder="Enter New To Do"
        value={name}
        onChange={(e) => setContent(e.target.value)}
      />
    </form>
  );
}

import TodoEntryForm from "./TodoEntryForm";
/** @format */

export default function List({ todos, deleter, adder, setList }) {
  const list = todos.length ? (
    todos.map((todo) => (
      <div key={todo.todoId} className="todo">
        <span onClick={() => deleter(todo.todoId)}>{todo.name}</span>
      </div>
    ))
  ) : (
    <p>Nothing To Do</p>
  );

  return (
    <div>
      <u>List Name</u>
      {list}
      <TodoEntryForm adder={adder} />
      <button onClick={() => setList("")}>Back to Lists</button>
    </div>
  );
}

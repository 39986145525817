import { useState, useEffect } from "react";
import { getUserByUsername, createUser } from "../../services/userServices";
/** @format */

export default function UserForm({ setUserId }) {
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage("");
  }, [username]);

  function submit(e) {
    e.preventDefault();
  }

  function createNewUser() {
    if (username !== "") {
      getUserByUsername(username).then((user) => {
        if (user === null) {
          createUser(username).then((newUser) => {
            if (newUser !== null) setUserId(newUser.userId);
            else setMessage("Error. Please try again.");
          });
        }
      });
    } else {
      setMessage("Username already exists");
    }
  }

  function login() {
    if (username !== "") {
      getUserByUsername(username).then((u) => {
        if (u !== null) {
          console.log("Line 36 u: " + u.userId);
          setUserId(u.userId);
        } else {
          setMessage("No such user, please Create New.");
        }
      });
    }
  }

  return (
    <form onSubmit={submit}>
      <input
        placeholder="Enter Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <div>
        <button onClick={() => login()}>Login</button>
        <button onClick={() => createNewUser()}>Create New</button>
      </div>
      {message !== "" && <div>{message}</div>}
    </form>
  );
}

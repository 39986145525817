/** @format */

import { useState, useEffect } from "react";
import List from "./components/ToDoComponents/List.js";
import Lists from "./components/UserComponents/Lists.js";
import UserForm from "./components/UserComponents/UserForm.js";
import { getTodos, createTodo, removeTodo } from "./services/todoServices.js";
import "./App.css";

function App() {
  const [todos, setTodos] = useState([]);
  const [listId, setList] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (listId !== "") {
      getTodos(listId).then(setTodos);
    }
  }, [listId]);

  function deleteTodo(todoId) {
    removeTodo(todoId).then(
      setTodos(todos.filter((todo) => todo.todoId !== todoId))
    );
  }

  function addTodo(todo) {
    createTodo(todo, listId, userId).then((t) => setTodos([...todos, t]));
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>Instructions</p>
        <div className="App-heading">Welcome to Tify</div>
        <p>
          Like Tify?{" "}
          <a href="https://www.paypal.com/paypalme/my/profile">
            Make a donation!
          </a>
        </p>
      </header>
      {userId === "" && <UserForm setUserId={setUserId} />}
      {userId !== "" && listId === "" && (
        <Lists userId={userId} setList={setList} />
      )}
      {listId !== "" && (
        <List
          todos={todos}
          deleter={deleteTodo}
          adder={addTodo}
          setList={setList}
        />
      )}
    </div>
  );
}

export default App;

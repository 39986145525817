import { useState, useEffect } from "react";
import { getLists } from "../../services/userServices";
/** @format */

export default function Lists({ userId, setList }) {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    if (userId !== "") {
      getLists(userId).then(setLists);
    } else {
      setLists("No Lists");
    }
  }, [userId]);

  return (
    <div>
      {lists.length > 0 &&
        lists.map((list) => (
          <div
            key={list.listId}
            onClick={() => setList(list.listId)}
            className="todo"
          >
            {list.name}
          </div>
        ))}
      {lists.length === 0 && "No Lists"}
    </div>
  );
}

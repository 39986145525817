import {
  getDocs,
  where,
  query,
  collection,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export async function getLists(userId) {
  const snapshot = await getDocs(
    query(collection(db, "lists"), where("userId", "==", userId))
  );

  if (snapshot.empty) return null;

  return snapshot.docs.map((list) => ({
    listId: list.id,
    name: list.data().name,
  }));
}

export async function getUserByUsername(username) {
  console.log("Line 25 username: " + username);
  const snapshot = await getDocs(
    query(
      collection(db, "users"),
      where("username", "==", username),
      orderBy("username")
    )
  );

  if (snapshot.empty) return null;

  return {
    username: snapshot.docs[0].data().username,
    userId: snapshot.docs[0].data().userId,
  };
}

export async function createUser(username) {
  getUserByUsername(username).then((user) => {
    if (user === null) {
      const data = {
        name: username,
      };
      const docRef = addDoc(collection(db, "users"), data);
      return docRef;
    }
  });
  return null;
}

import {
  getDocs,
  query,
  collection,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export async function getTodos(listId) {
  const snapshot = await getDocs(
    query(collection(db, "todos"), where("listId", "==", listId))
  );

  if (snapshot.empty) return null;

  return snapshot.docs.map((todo) => ({
    todoId: todo.id,
    listId: todo.listId,
    name: todo.data().name,
  }));
}

export async function createTodo(name, listId, userId) {
  const data = {
    name: name,
    listId: listId,
    userId: userId,
  };
  const docRef = await addDoc(collection(db, "todos"), data);
  const updatedData = {
    ...data,
    todoId: docRef.id,
  };
  await updateDoc(docRef, updatedData);
  return { ...updatedData };
}

export async function removeTodo(todoId) {
  await deleteDoc(doc(db, "todos", todoId));
}

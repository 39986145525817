// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyATTZljCgs-d3uiMA-JnUg1VQhN60r8lQg",
  authDomain: "tify-99b74.firebaseapp.com",
  projectId: "tify-99b74",
  storageBucket: "tify-99b74.appspot.com",
  messagingSenderId: "957776132174",
  appId: "1:957776132174:web:61ada7555a2638ae9d6b1f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);
